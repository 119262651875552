<template>
    <div
        @click="goInProject"
        @mouseenter="mouseHover"
        @mouseleave="mouseOut"
        class="searched-item"
    >
        <div class="search-item-content">
            <div class="left">
                <h2 class="title luxury">
                    {{ transformedApartment.typeName }}
                </h2>
                <div class="area-inner">
                    <span class="description mobile-desc"
                    >{{ $fn.tr("Area") }}:</span
                    >
                    <span class="description"
                    >{{ transformedApartment.area }} M2</span
                    >
                </div>
                <div
                    class="offer-container"
                    v-if="transformedApartment.offerTitle"
                >
                    <span class="offer-title fira">{{
                            transformedApartment.offerTitle
                        }}</span>
                </div>
            </div>
            <div class="right">
                <div
                    v-html="transformedApartment.img"
                    class="apartment-image"
                ></div>
                <div class="item">
                    <span class="description"
                    >{{ $fn.tr("Ap") }}.
                        {{ transformedApartment.title }}</span
                    >
                    <span class="description"
                    >{{ $fn.tr("Block") }}:
                        {{ transformedApartment.block }}</span
                    >
                    <span class="description"
                    >{{ $fn.tr("Floor") }}:
                        {{ transformedApartment.floor }}</span
                    >
                </div>
                <div class="item view-inner">
                    <span class="description">{{ $fn.tr("View") }}:</span>
                    <div
                        v-for="item in transformedApartment.views"
                        class="taxonomy-list-item"
                    >
                        <div class="logo" v-html="item.viewsLogo"></div>
                        <span class="description">{{ item.title }}</span>
                    </div>
                </div>
                <div class="item price-inner">
                    <span class="description">{{ $fn.tr("Price") }}:</span>
                    <p v-if="transformedApartment.show_discount_price_m2==='1'"
                       class="description fira offer-price">
                        {{ activeCurrency.code }} {{ discountMoneyFormat }}
                        <span>
                            {{ activeCurrency.code }} {{ moneyFormat }}</span
                        >
                    </p>
                    <span v-else class="description">
                        {{ activeCurrency.code }} {{ moneyFormat }}</span>
                </div>
                <svg
                    width="20"
                    height="17"
                    viewBox="0 0 20 17"
                    class="heart"
                    :class="{ active: transformedApartment.favorite }"
                    @click="addFavorite"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M14.515 0.662109C13.2079 0.662109 11.9015 1.13027
                    10.9003 2.07764L10.0412 2.88415L9.1983 2.08539C7.19556
                    0.190837 3.97153 0.190837 1.96885 2.08539C-0.0338866
                    3.97994 -0.0338866 7.1022 1.96885 8.99686C4.48396
                    11.3763 6.99923 13.7503 9.51429 16.1295C9.81549
                    16.4117 10.2913 16.4117 10.5923 16.1295L18.1295
                     8.98884C20.1295 7.09306 20.132 3.97203 18.1295
                      2.07737C17.1281 1.13018 15.822 0.662109 14.515 0.662109Z"
                        fill="transparent"
                    />
                </svg>
            </div>

            <svg
                width="23"
                height="22"
                @click="addFavorite"
                v-if="showClose"
                class="close-icon"
                viewBox="0 0 23 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M1.59375 1.55444L21.0392 20.9999"
                    stroke="#940128"
                    stroke-width="2"
                    stroke-linecap="round"
                />
                <path
                    d="M1.59375 21L21.0392 1.55457"
                    stroke="#940128"
                    stroke-width="2"
                    stroke-linecap="round"
                />
            </svg>
        </div>
        <div
            v-html="transformedApartment.img"
            class="mobile-apartment-image"
        ></div>
    </div>
</template>

<script setup>
import {computed, inject} from "vue";
import {onMounted} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useMenuStore} from "../../../../store/pinia/menu";
import {useProjects} from "../../../../store/pinia/projects";
import {UseFavoriteFlats} from "../../../../store/pinia/FavoriteProjects";

let router = useRouter();
const route = useRoute();
let props = defineProps({
    apartment: {
        type: Object,
        required: true,
    },
    showClose: {
        type: Boolean,
        default: false,
    },
});
let store = useMenuStore();
let favorite = UseFavoriteFlats();
let projectStore = useProjects();
let projectViews = store.indx?.terms?.category?.filter(
    (item) => item.show_in_flat?.value === "1"
);
let taxonomyType = store.indx?.terms?.category?.filter(
    (item) => item.show_in_filter?.value === "1"
);
let offerTaxonomy = store.indx?.terms?.offers;
let favoriteFlats = computed(() => favorite.flats);
let usd = computed(() => store?.indx.usd?.usd);

let transformedApartment = computed(() => {
    let apart = props.apartment;
    let type = apartmentTypes.value?.find((item) => item.id === apart?.type_id) || {};
    let block = blocks.value?.find((item) => item.id === apart?.block_id) || {};
    let taxonomy = taxonomyType
        .filter((view) => type.taxonomies?.includes(view.id))
        ?.map((item) => ({
            ...item,
            viewsLogo: item?.logo?.logo,
        }))?.[0];
    let offer = offerTaxonomy?.find(
        (item) => item.id === apart?.taxonomies?.[0]
    );
    return {
        ...apart,
        typeName: taxonomy?.title,
        area: type.area,
        showPrice: apart.show_price_m2
            ? apart.price_m2
            : apart.price_m2 * type.area,
        discountPrice: apart.show_price_m2
            ? apart.discount_price_m2
            : apart.discount_price_m2 * type.area,
        block: block.title,
        img: type?.render?.tabs?.[0]?.render_svg,
        views: projectViews
            ?.filter((view) => type.taxonomies?.includes(view.id))
            ?.map((item) => ({
                ...item,
                viewsLogo: item?.logo?.logo,
            })),
        offerTitle: offer?.title,
        favorite: !!favoriteFlats.value?.filter((item) => item.id === apart.id)
            ?.length,
    };
});
const goInProject = () => {
  let url = `/${store.indx.locale}/projects/${projectStore._activeProject.slug}/apartments/apview/block-${transformedApartment.value.block}/floor-${transformedApartment.value.floor}/${transformedApartment.value.id}-apartment-${transformedApartment.value.title}`;
  let lastChar = url.charAt(url.length - 1);
    if (lastChar === "/") url.slice(0, -1);

    router.push({
        path: url,
        query: "",
    });
};

function formatMoney(number) {
    return (Math.round(number * 100) / 100).toLocaleString();
}

let activeCurrency = computed(() => projectStore.currency);
let moneyFormat = computed(() => {
    return activeCurrency.value?.code === "USD"
        ? formatMoney(Math.floor(transformedApartment.value?.showPrice))
        : formatMoney(
            Math.floor(transformedApartment.value?.showPrice * usd.value)
        );
});
let discountMoneyFormat = computed(() => {
    return activeCurrency.value?.code === "USD"
        ? formatMoney(Math.floor(transformedApartment.value?.discountPrice))
        : formatMoney(
            Math.floor(transformedApartment.value?.discountPrice * usd.value)
        );
});

const {apartmentTypes, blocks, floors} = inject("project");
const mouseHover = () => {
    let pathes = document.querySelectorAll(".project-svg path");
    let filterItem = Array.from(pathes).filter((item) => {
        return (
            item.attributes.floor.value == transformedApartment.value.floor &&
            item.attributes.block.value == transformedApartment.value.block
        );
    });

    filterItem[0]?.classList.add("active");
};
const mouseOut = () => {
    let path = document.querySelector(".project-svg path.active");
    path?.classList.remove("active");
};
const addFavorite = (event) => {
    event.stopPropagation();
    event.preventDefault();
    favorite.addFavorite(transformedApartment.value.id);
};
onMounted(() => {
});
</script>

<style lang="scss" scoped>
.searched-item {
    padding: 24px 43px;
    position: relative;
    display: flex;
    align-items: center;
    color: $primaryNavy;
    border-bottom: 1px solid rgba(17, 25, 41, 0.2);
    @media only screen and (max-width: 1650px) and (min-width: 1440px) {
        padding: 24px;
    }
    @media only screen and (max-width: 1439px) and (min-width: 1024px) {
        padding: 10px;
    }
    @media only screen and (max-width: 1023px) and (min-width: 768px) {
        padding: 12px;
    }
    @media only screen and (max-width: 767px) {
        padding: 16px 22px;
        background: rgba(207, 203, 191, 0.3);
        border: unset;
        margin-bottom: 20px;
    }

    &:hover {
        background: rgba(207, 203, 191, 0.3);
    }

    .mobile-apartment-image {
        display: none;
        @media only screen and (max-width: 767px) {
            display: block;
            width: 77px;
        }
    }

    .search-item-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        @media only screen and (max-width: 767px) {
            display: block;
        }
    }

    .description {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        font-family: FiraGO;
        display: block;
        @media only screen and (max-width: 1023px) {
            font-size: 14px;
        }
    }

    .left {
        .area-inner {
            display: flex;
            align-items: center;

            .mobile-desc {
                display: none;
                margin-right: 10px;
                @media only screen and (max-width: 767px) {
                    display: block;
                }
            }
        }

        .offer-container {
            background: #940128;
            border-radius: 3px;
            padding: 4px 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 6px;
            width: fit-content;

            .offer-title {
                font-style: normal;
                font-weight: 600;
                font-size: 13px;
                color: white;
            }
        }

        .title {
            font-style: normal;
            font-weight: 400;
            font-size: 34px;
            text-transform: uppercase;
            @media only screen and (max-width: 1650px) and (min-width: 1024px) {
                font-size: 22px;
            }
            @media only screen and (max-width: 1023px) {
                font-size: 22px;
            }
        }
    }

    .right {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 65%;
        @media only screen and (max-width: 1439px) and (min-width: 1024px) {
            width: 70%;
        }
        @media only screen and (max-width: 767px) {
            width: 100%;
            display: block;
        }

        .item {
            flex: 15%;
            @media only screen and (max-width: 1439px) and (min-width: 1024px) {
                flex: unset;
            }
            @media only screen and (max-width: 767px) {
                display: flex;
                .description {
                    margin-right: 10px;
                }
            }

            &.view-inner {
                @media only screen and (max-width: 1439px) and (min-width: 1024px) {
                    display: none;
                }
            }

            &.price-inner {
                align-self: flex-start;
            }

            .offer-price {
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 28px;

                span {
                    color: $burgundy;
                    text-decoration: line-through;
                    display: block;
                }
            }
        }

        .apartment-image {
            width: 132px;
            @media only screen and (max-width: 1439px) and (min-width: 1024px) {
                width: 80px;
            }
            @media only screen and (max-width: 767px) {
                display: none;
            }
        }

        .taxonomy-list-item {
            display: flex;
            align-items: center;

            .logo {
                margin-right: 8px;
            }
        }

        .heart {
            @media only screen and (max-width: 767px) {
                display: none;
            }

            &.active {
                path {
                    fill: $burgundy;
                    stroke-width: 0;
                }
            }

            path {
                stroke: $primaryNavy;
                stroke-width: 2px;
                transition: all 0.4s ease-out;
            }
        }
    }

    .close-icon {
        position: absolute;
        cursor: pointer;
        right: 13px;
        top: 13px;
    }
}
</style>

<style lang="scss">
html[lang="ka"] {
    .searched-item .left .title {
        font-size: 30px;
        @media (max-width: 1440px) {
            font-size: 22px;
        }
    }
}

body {
    &.is-dark {
        .searched-item {
            color: white;
        }

        .heart {
            path {
                stroke: white !important;
            }
        }

        .taxonomy-list-item {
            path {
                fill: white !important;
            }
        }
    }
}
</style>
