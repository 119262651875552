<template>
    <div :class="{ exit: exitAnim }" class="apartment-favorite-list">
        <div class="apartment-favorite-list-content">
            <div class="header">
                <svg width="20" height="17" viewBox="0 0 20 17"
                     class="heart"
                     fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M14.515 0.662109C13.2079 0.662109 11.9015 1.13027
                    10.9003 2.07764L10.0412 2.88415L9.1983 2.08539C7.19556
                    0.190837 3.97153 0.190837 1.96885 2.08539C-0.0338866
                    3.97994 -0.0338866 7.1022 1.96885 8.99686C4.48396
                    11.3763 6.99923 13.7503 9.51429 16.1295C9.81549
                    16.4117 10.2913 16.4117 10.5923 16.1295L18.1295
                     8.98884C20.1295 7.09306 20.132 3.97203 18.1295
                      2.07737C17.1281 1.13018 15.822 0.662109 14.515 0.662109Z"
                        fill="transparent"/>
                </svg>
                <span class="title fira">{{ $fn.tr('Favorotes') }}</span>
            </div>
            <div class="flats-list">
                <ApartmentSearchResultItem v-for="item in flats"
                                           :apartment="item"
                                           :showClose="true"
                                           :key="item.id"/>
            </div>
            <div @click="close" class="close-inner">
                <div class="close"></div>
            </div>
        </div>
    </div>
</template>

<script setup>
import ApartmentSearchResultItem from './ApartmentSearchResultItem'
import {computed, onBeforeUnmount, onMounted, onUnmounted, ref} from "vue";
import {UseFavoriteFlats} from "../../../../store/pinia/FavoriteProjects";

let closeTimeout = ref(null)
let exitAnim = ref(false)
let favorites = UseFavoriteFlats()
let flats = computed(() => favorites.flats)
const close = () => {
    exitAnim.value = true;
    closeTimeout.value = window.setTimeout(() => {
        favorites.setFavoriteListVisibility(false)
    }, 600);
}
onBeforeUnmount(() => {
    clearTimeout(closeTimeout.value)
})
</script>


<style lang="scss" scoped>
.apartment-favorite-list {
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    background: rgba(17, 25, 41, 0.2);
    width: 100%;
    height: 100vh;
    animation: bgOpen 0.4s;

    &.exit {
        animation: bgExit 0.4s;
        animation-delay: 0.4s;

        .apartment-favorite-list-content {
            animation: exitAnim 0.6s !important;
        }
    }

    .apartment-favorite-list-content {
        width: 576.92px;
        background: #FFFFFF;
        height: 100vh;
        padding: 70px 16px 70px 50px;
        position: relative;
        transition: 0.6s;
        animation: openAnim 0.6s;

        @media (max-width: 767px) {
            width: 100%;
            padding: 40px 16px 40px;
        }

        .header {
            display: flex;
            align-items: center;
            padding-bottom: 35px;
            border-bottom: 2px solid rgba(191, 191, 191, 0.2);
            padding-right: 70px;

            .title {
                font-weight: 700;
                font-size: 13px;
                letter-spacing: 0.1em;
                text-transform: uppercase;
            }

            svg {
                margin-right: 13px;

                path {
                    stroke-width: 2px;
                    stroke: $primaryNavy;
                }
            }

        }

        .flats-list {
            padding-right: 70px;
            margin-top: 37px;
            overflow-y: auto;
            overflow-x: hidden;
            max-height: calc(100vh - 200px);
            @media (max-width: 1023px) {
                padding-right: 10px;
            }

            &::-webkit-scrollbar {
                background: transparent;
                width: 4px;
                border-radius: 10px;
            }

            &::-webkit-scrollbar-track {
            }

            &::-webkit-scrollbar-thumb {
                background: $burgundy;
                border-radius: 10px;
            }
        }

        .close-inner {
            position: absolute;
            width: 78px;
            height: 78px;
            top: 26px;
            right: 0;
            transform: translateX(50%);
            border-radius: 50%;
            background: $primaryNavy;
            border: 2px solid #FFFFFF;
            cursor: pointer;

            .close {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 32px;
                height: 32px;

                &:before, &:after {
                    position: absolute;
                    left: 15px;
                    content: ' ';
                    height: 33px;
                    width: 2px;
                    background-color: white;
                }

                &:before {
                    transform: rotate(45deg);
                }

                &:after {
                    transform: rotate(-45deg);
                }
            }

            @media (max-width: 767px) {
                width: 58px;
                height: 58px;
                top: 15px;
                right: 15px;
                transform: translateX(0);
            }
        }
    }

    .searched-item:deep {
        padding: 16px 22px;
        background: rgba(207, 203, 191, 0.3);
        border: unset;
        margin-bottom: 20px;

        .mobile-apartment-image {
            display: block;
            width: 77px;
        }

        .search-item-content {
            display: block;
        }

        .description {
            font-size: 14px;
        }

        .left {
            .area-inner {
                display: flex;
                align-items: center;

                .mobile-desc {
                    display: block;
                }
            }

            .title {
                font-size: 22px;
            }
        }

        .right {
            width: 100%;
            display: block;

            .item {
                display: flex;

                .description {
                    margin-right: 10px;
                }
            }

            .apartment-image {
                display: none;
            }


            .heart {
                display: none;
            }
        }
    }

    @keyframes openAnim {
        from {
            opacity: 0;
            transform: translateX(-200px);
        }
        to {
            opacity: 1;
            transform: translateX(0px);
        }
    }
    @keyframes exitAnim {
        from {
            opacity: 1;
            transform: translateX(0px);
        }
        to {
            opacity: 0;
            transform: translateX(-200px);
        }
    }
    @keyframes bgExit {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }
    @keyframes bgOpen {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
}
</style>

<style lang="scss">
body {
    &.is-dark {
        .apartment-favorite-list-content {
            background: $primaryNavy !important;
        }
    }
}

</style>
