import { defineStore } from "pinia";
import func from "../../../helpers/func";
import { useMenuStore } from "../menu";

let menuStore = useMenuStore();

export const useProjects = defineStore("todos", {
    state: () => ({
        activeApartmentTypes: 0,
        activeApartmentBlocks: [],
        activeApartmentFloors: [],
        activeApartmentCategories: [],
        _activeProject: {},
        areaRange: [],
        priceRange: [],
        projects: [],
        _flats: [],
        orderBy: "asc",
        orderField: "price",
        _currency: {
            code: "USD",
        },
        isQueryParameters: false,
    }),
    getters: {
        getActiveApartmentType(state) {
            return state.activeApartmentTypes;
        },
        flats(state) {
            return state._flats;
        },
        activeProject(state) {
            return state._activeProject;
        },
        currency(state) {
            return state._currency;
        },
        getIsQueryParameters(state) {
            return state.isQueryParameters;
        },
    },
    actions: {
        async getFlats(state, pageNumber = 1) {
            let { data } = await func.postData("dev/getFlats", {
                projectId: this.activeProject?.id,
                typeId: this.activeApartmentTypes,
                blockId: this.activeApartmentBlocks,
                priceMin:
                    this.currency?.code === "USD"
                        ? this.priceRange[0] + 1
                            ? Math.floor(this.priceRange[0])
                            : undefined
                        : Math.floor(
                              this.priceRange[0] / menuStore?.indx.usd?.usd
                          ),
                priceMax:
                    this.currency?.code === "USD"
                        ? this.priceRange[1]
                            ? Math.floor(this.priceRange[1])
                            : undefined
                        : Math.floor(
                              this.priceRange[1] / menuStore?.indx.usd?.usd
                          ),
                areaMin: this.areaRange[0],
                areaMax: this.areaRange[1],
                floor: this.activeApartmentFloors?.map((item) => Number(item)),
                categoryId: this.activeApartmentCategories,
                orderBy: this.orderBy,
                orderField: this.orderField,
                pageNumber: pageNumber,
            });
            this._flats = data;
        },
        updateFlatsPrice(currency) {
            this._currency = currency;
        },
        setActiveProject(activeProject) {
            this._activeProject = activeProject;
        },
        setQueryParameters(data) {
            if (data.activeApartmentTypes)
                this.activeApartmentTypes = data.activeApartmentTypes;
            if (data.activeApartmentBlocks)
                this.activeApartmentBlocks = data.activeApartmentBlocks;
            if (data.activeApartmentFloors)
                this.activeApartmentFloors = data.activeApartmentFloors;
            if (data.areaRange) this.areaRange = data.areaRange;
            if (data.priceRange) this.priceRange = data.priceRange || [];
            if (data.activeApartmentCategories)
                this.activeApartmentCategories = data.activeApartmentCategories;
            if (data.orderBy) this.orderBy = data.orderBy;
            if (data.orderField) this.orderField = data.orderField;
        },
        setIsQueryParameters(value) {
            this.isQueryParameters = value;
        },
    },
});
