<template>
    <div class="image-speed">
        <div
            @click="itemClick($event)"
            @mouseover="hovered = true"
            :class="{ hovered }"
            class="hovered-image-container left"
            @mouseenter="menuStore.getComponentCursor($event, 'scale')"
            @mouseleave="menuStore.getComponentCursor($event, null)"
        >
            <img
                :src="item.image"
                :id="clickedTargetRef"
                ref="imageItem"
                class="image"
                alt=""
            />
            <div style="opacity: 0" class="shadow-container"></div>
        </div>
        <teleport to="body">
            <div
                @click="scaleDown"
                v-if="clicked"
                @mouseenter="
                    menuStore.getComponentCursor($event, 'zoom-cancel')
                "
                id="scale-container"
                class="scale-container"
            >
                <div class="scale-image-container" id="scale-target">
                    <img
                        :src="item.image"
                        style="opacity: 0"
                        :id="clickedTargetRef + '-clone'"
                        class="image"
                        alt=""
                    />
                </div>
                <div class="close-container">
                    <svg
                        class="icon"
                        width="15.556"
                        height="15.556"
                        viewBox="0 0 15.556 15.556"
                    >
                        <g
                            id="Group_55"
                            data-name="Group 55"
                            transform="translate(7.778 -4.95) rotate(45)"
                        >
                            <g
                                id="Group_74"
                                data-name="Group 74"
                                transform="translate(0 0)"
                            >
                                <line
                                    id="Line_5"
                                    data-name="Line 5"
                                    x2="18"
                                    transform="translate(0 9)"
                                    fill="none"
                                    stroke="#E5B676"
                                    stroke-linecap="round"
                                    stroke-width="2"
                                />
                                <line
                                    id="Line_15"
                                    data-name="Line 15"
                                    y2="18"
                                    transform="translate(9)"
                                    fill="none"
                                    stroke="#E5B676"
                                    stroke-linecap="round"
                                    stroke-width="2"
                                />
                            </g>
                        </g>
                    </svg>
                </div>
            </div>
        </teleport>
    </div>
</template>

<script setup>
import {computed, nextTick, onMounted, ref} from "vue";
import {useScale} from "../../store/pinia/scaleImage";
import {useMenuStore} from "../../store/pinia/menu";
import gsap from "gsap";
//props
let props = defineProps({
    item: {
        type: Object,
        required: true,
    },
    id: {
        type: String,
        required: false,
    },
});

///data
let store = useScale();
let menuStore = useMenuStore();
let hovered = ref(false);
let clicked = ref(false);
let clickedItemPosition = ref({});
let targetItem = ref("");
let imageItem = ref("");
//computed
let clickedElementStyles = computed(() => {
    return {
        top: `${clickedItemPosition.value?.top}px`,
        width: `${clickedItemPosition.value?.width}px`,
        height: `${clickedItemPosition.value?.height}px`,
        right: `${clickedItemPosition.value?.right}px`,
        transform: `translate(${delta.value}px,-${delta.value}px)`,
        position: "absolute",
    };
});
let delta = computed(() => {
    let padding = 50;
    if (window.innerWidth < 1441) {
        padding = 30;
    }
    if (window.innerWidth < 767) {
        padding = 16;
    }
    return padding;
});
const clickedTargetRef = computed(() => {
    let randomNumber = randomIntFromInterval(1, 500000);

    return props.id ? props.id : `image-scale-${randomNumber}`;
});
//methods
const randomIntFromInterval = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
};
const setTargetItem = async () => {
    await nextTick();
    targetItem.value = imageItem.value;
};

const setStylesOnElement = (styles, element) => {
    Object.assign(element.style, styles);
};
const itemClick = (event) => {
    if (window.innerWidth < 1024) return;
    if (store.animated) return;
    hovered.value = false;
    store.setOpened(true);
    clicked.value = true;
    menuStore.disableScrollStatus = true;
    store.setAnimated(true);
    setTimeout(() => {
        mouseMoveHandler();
        setItemPosition(event);
    }, 300);
};
const setItemPosition = () => {
    let item = targetItem.value;
    let itemClone = document.querySelector(`#${clickedTargetRef.value}-clone`);
    let {width, height, right, top} = item.getBoundingClientRect();
    clickedItemPosition.value = {
        top,
        right: window.innerWidth - right,
        width,
        height,
    };

    setStylesOnElement(clickedElementStyles.value, itemClone);

    initClickedItemAnimation({
        top,
        right: window.innerWidth - right,
        width,
        height,
    });
};
const initClickedItemAnimation = ({right, top, width, height}) => {
    let newWidth = window.innerWidth - 2 * delta.value;
    let aspectRatio = height / width;
    let newHeight = newWidth * aspectRatio;
    let scaleX = newWidth / width;
    let scaleY = newHeight / height;
    let transformX = -(
        window.innerWidth -
        (right + width + (newWidth - width) / 2) -
        2 * delta.value
    );
    let transformY =
        newHeight / 2 -
        top -
        height / 2 -
        delta.value +
        (window.innerHeight - newHeight) / 2;

    if (window.innerWidth < 1024) {
        aspectRatio = width / height;
        newHeight = window.innerHeight - 2 * delta.value;
        newWidth = aspectRatio * newHeight;
        transformX =
            -(
                innerWidth -
                (right + width + (newWidth - width) / 2) -
                delta.value
            ) -
            (newWidth - innerWidth) / 2;
        transformY = newHeight / 2 - top - height / 2;
        scaleX = newWidth / width;
        scaleY = newHeight / height;
    }

    gsap.set(targetItem.value, {
        opacity: 0,
        delay: 0.2,
    });
    gsap.set(".scale-container img", {
        opacity: 1,
    });
    gsap.to(".scale-container img", {
        duration: 1,
        force3D: false,
        x: transformX,
        y: transformY,
        scaleX: scaleX,
        scaleY: scaleY,
        ease: "",
        onComplete: () => {
            gsap.to(".scale-container", {
                background: "white",
                pointerEvents: "auto",
            });
            gsap.to(".scale-container  .close-container", {
                opacity: 1,
                onComplete: () => {
                    store.setAnimated(false);
                },
            });
        },
    });
};
const scaleDown = ($ev, immediate = false) => {
    if (store.animated) return;
    store.setOpened(false);
    store.setAnimated(true);
    let scaledImage = document.querySelector(".scale-container img");

    gsap.to(".scale-container,.scale-image-container", {
        background: "transparent",
    });
    gsap.to(".scale-container  .close-container", {
        opacity: 0,
    });
    gsap.to(".scale-container  .content", {
        opacity: 0,
        onComplete: () => {
            gsap.to(scaledImage, {
                duration: immediate ? 0 : 1,
                force3D: false,
                transform: `translate(${delta.value}px,-${delta.value}px) scale(1) perspective(0)`,
                ease: "",
                onComplete: () => {
                    gsap.set(targetItem.value, {
                        opacity: 1,
                        delay: immediate ? 0 : 0.1,
                        onComplete: () => {
                            setTimeout(() => {
                                menuStore.disableScrollStatus = false;
                                gsap.to(".scale-container", {
                                    pointerEvents: "none",
                                });
                                clicked.value = false;
                                store.setAnimated(false);
                                removeMouseMoveHandler();
                            }, 30);
                        },
                    });
                },
            });
        },
    });
};
const mousemove = (ev) => {
    if (store.animated || window.innerWidth < 1025) return;
    const image = document.querySelector(".scale-container img");
    let itemHeight = image?.getBoundingClientRect()?.height;

    if (!image || itemHeight < innerHeight - 2 * delta.value) return;

    const clamp = (num, min, max) => {
        return num < min ? min : num > max ? max : num;
    };
    let maxDelta = (itemHeight - innerHeight) / 2;
    let a = innerHeight / 2 - ev.clientY;
    let del = (a * (innerHeight / 2 + maxDelta)) / (innerHeight / 2);
    let itemRealHeight = gsap.getProperty(image, "height");
    let itemTop = gsap.getProperty(image, "top");
    let itemBottom = innerHeight - itemTop - itemRealHeight;
    let topMaxDelta = (itemHeight - itemRealHeight) / 2 - itemTop;
    let bottomMaxDelta = -(
        (itemHeight - itemRealHeight) / 2 -
        itemBottom +
        2 * delta.value
    );
    if (window.innerWidth < 768) {
        gsap.to(image, {
            x: clamp(del, bottomMaxDelta, topMaxDelta),
        });
    } else {
        gsap.to(image, {
            y: clamp(del, bottomMaxDelta, topMaxDelta),
        });
    }
};
const mouseMoveHandler = () => {
    window.addEventListener("mousemove", mousemove, false);
};
const removeMouseMoveHandler = () => {
    window.removeEventListener("mousemove", mousemove);
};

onMounted(() => {
    setTargetItem();
});
</script>

<style lang="scss" scoped>
$ease-out: cubic-bezier(0.07, 0.29, 0, 0.99);

.image-speed {
    pointer-events: auto;
    z-index: 1;

    .hovered-image-container {
        position: relative;
        overflow: hidden;
        transition: all 0.6s 0.2s $ease-out;

        .shadow-container {
            position: absolute;
            height: 100%;
            width: 100%;
            background: rgba(0, 0, 0, 0.2);
            pointer-events: none;
            opacity: 0;
            top: 0;
            left: 0;
            transition: opacity 0.3s $ease-out;
            @media only screen and (max-width: 1023px) {
                opacity: 1;
            }
        }

        &.hovered {
            .title,
            .shadow-container {
                opacity: 1;
                transform: translateY(0);
            }
        }

        .image {
            width: 100%;
        }

        .content {
            position: absolute;
            bottom: 52px;
            left: 62px;
            overflow: hidden;
            pointer-events: none;
            @media only screen and (max-width: 1023px) {
                display: none;
                left: 30px;
            }
        }

        .title {
            position: relative;
            display: block;
            left: 0;
            top: 0;
            font-weight: bold;
            color: white;
            opacity: 0;
            transform: translateY(100%);
            transition: all 0.3s $ease-out;
        }
    }
}
</style>

<style lang="scss">
.scale-container {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100%;
    z-index: 999;
    pointer-events: none;
    overflow: hidden;

    .anim {
        img {
        }
    }

    @media only screen and (max-width: 1023px) {
        z-index: 99;
    }

    .scale-image-container {
        position: relative;
        height: calc(100vh - 100px);
        width: calc(100% - 100px);
        overflow: hidden;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        @media (max-width: 767px) {
            .image {
                transition: 0.6s;
            }
        }
        @media only screen and (max-width: 1441px) and (min-width: 768px) {
            height: calc(100vh - 60px);
            width: calc(100% - 60px);
        }
        //@media only screen and (max-width: 1365px) and (min-width: 768px) {
        //    height: calc(100vh - 30px);
        //    width: calc(100% - 30px);
        //}
        @media only screen and (max-width: 767px) {
            height: calc(100vh - 32px);
            width: calc(100% - 32px);
            //height: 100%;
            //width: 100%;
        }
    }

    .image {
        transform-origin: center;
    }

    .content {
        position: absolute;
        left: 120px;
        bottom: 137px;
        opacity: 0;
        text-align: left;
        z-index: 1;
        @media only screen and (max-width: 1650px) and (min-width: 1440px) {
            left: 90px;
            bottom: 90px;
        }
        @media only screen and (max-width: 1439px) and (min-width: 1024px) {
            left: 128px;
            bottom: 90px;
        }

        @media only screen and (max-width: 1023px) and (min-width: 768px) {
            left: 90px;
            bottom: 90px;
        }

        @media only screen and (max-width: 767px) {
            left: 50px;
            bottom: 70px;
        }

        .header-title {
            // display: none;
            font-weight: normal;
            line-height: unset !important;
            @media only screen and (max-width: 1650px) and (min-width: 1440px) {
                font-size: 55px;
            }
            @media only screen and (max-width: 1439px) and (min-width: 768px) {
                font-size: 55px;
            }
            @media only screen and (max-width: 767px) {
                font-size: 34px;
            }
        }

        .place {
            font-size: 21px;
            color: #e5b676;
            margin-top: 22px;
            font-weight: normal;
            max-width: 80%;
            @media only screen and (max-width: 1365px) and (min-width: 768px) {
                margin-top: 30px;
            }
            @media only screen and (max-width: 768px) {
                margin-top: 20px;
                max-height: 200px;
                overflow-y: auto;
            }
        }

        .position {
            font-size: 16px;
            color: white;
            font-weight: normal;
            margin-top: 10px;
            max-width: 600px;
        }
    }

    .close-container {
        border: 1px solid rgba(193, 148, 50, 0.3);
        display: none;
        right: 50px;
        top: 50px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        z-index: 3;
        position: absolute;
        align-items: center;
        justify-content: center;
        opacity: 0;
        pointer-events: none;
        @media only screen and (max-width: 1023px) {
            display: flex;
        }
    }
}
</style>
